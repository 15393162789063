
.list{
    list-style-type: none;
    margin-bottom: 1rem;
}

.list_item {
    border: 1px solid #ccc;
    border-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.list__item:last-child {
    border-bottom: 1px solid #ccc;
}

.list__link {
  color: black;
  text-decoration: none;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  padding: 1rem;
  display: block;
    width: 100%;
    text-align: left;
}

.list__link:hover {
    background-color: #ccc;
}