.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

.form__group{
    width: 50%;
    margin-bottom: 1rem;
}

.form__input{
width: 100%;
border: 1px solid #ceafd4;
}

Form_Label{
    margin-bottom: 1rem;
    text-align: left;
}