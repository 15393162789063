.modal {
    position: fixed;
    background-color: darkgrey;
    z-index: 9999;
    display: none;
    position: fixed;
    padding-top: 1rem;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: black;
    background-color: rgba(0,0,0,0.4);
}

.modal--active {
    display: block;
}

.modal__content {
padding: 2rem;
width: 50%;
margin: 0 auto;
background-color: antiquewhite;
position: relative;
}

.modal__close {
    position: absolute;
    right: 1rem;
    top: 0.5rem;
    cursor: pointer;
}

.modal__title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 1rem;


}